/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


:root {
    --desired-size: 20px;
    --coefficient: 1vmin;
    --size: calc(var(--desired-size) * var(--coefficient));
    --primary-color: hsl(320, 100%, 50%);
    --chord: hsl(45, 100%, 40%);
    --shine: hsla(0, 0%, 100%, 0.75);
  }
  

  
  .balloon {
    --primary-color: hsla(var(--h, 0), 100%, 50%, 0.8);
    --size: calc(var(--s, 10) * var(--coefficient));
    height: calc(1.2 * var(--size));
    width: var(--size);
    background: var(--primary-color);
    border-radius: 50% 50% 50% 50% / 45% 45% 55% 55%;
    position: absolute;
    top: 50%;
    left: calc(var(--x, 50) * 1%);
    animation: float calc(var(--d, 1) * 1s) calc(var(--delay, 0) * -1s) infinite linear both;
  }
  
  .balloon:before {
    content: '';
    position: absolute;
    width: 20%;
    height: 30%;
    background: blue;
    top: 8%;
    left: 16%;
    border-radius: 50%;
    transform: rotate(40deg);
    background: var(--shine);
  }
  
  
  .balloon__handle {
    width: 2%;
    height: 60%;
    background: var(--chord);
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
  .balloon__handle:before,
  .balloon__handle:after {
    content: '';
    position: absolute;
    height: 5%;
    transform: translate(-50%, 0);
    border-radius: 25% / 50%;
    left: 50%;
  }
  
  .balloon__handle:before {
    top: 0;
    background: var(--chord);
    width: 500%;
  }
  
  .balloon__handle:after {
    top: 5%;
    background: var(--primary-color);
    width: 700%;
  }
  
  
  @keyframes float {
    from {
      transform: translate(-50%, -50%) translate(0, 100vh);
    }
    to {
      transform: translate(-50%, -50%) translate(0, -100vh);
    }
  }